import { LoginService } from './../../pages/login/shared/login.service';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from './storage.service';
import { UserData } from '../models/user-data-model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loggedIn$ = new BehaviorSubject(false);

  constructor(
    public router: Router,
    public loginService: LoginService,
    public toastr: ToastrService,
    public storageService: StorageService
  ) {
    this.setLoggedIn(this.hasToken());
  }

  hasToken(): boolean {
    if (this.storageService.getItem('token')) {
      return true;
    } else {
      return false;
    }
  }

  getToken() {
    return JSON.parse(this.storageService.getItem('token'));
  }

  getUserData(): UserData{
    return JSON.parse(this.storageService.getItem('userData'));
  }

  getTokenDecode() {
    let token: any = null;
    let tokenDecode: any = null;
    if (this.hasToken()) {
      token = this.getToken();
      if (token !== undefined && token) {
        tokenDecode = jwt_decode(token.session_id);
      }
    }
    return tokenDecode;
  }

  clearToken() {
    this.storageService.removeItem('token');
    this.loggedIn$.next(null);
  }


  setLoggedIn(value: boolean) {
    // update loggedin status in loggedIn$ stream.
    this.loggedIn$.next(value);
  }

  login(username: string, password: string, keepSession: boolean) {
    // this.storageService.removeItem('token');
    const payload = new HttpParams()
      .set('username', username)
      .set('password', password);

    return this.loginService.login(payload)
      .subscribe(response => {

        if (response && response.token) {
          const userData: UserData = {
            name: response.userData.USUARIO,
            fullName: response.userData.NOME.toLowerCase()
                                            .split(' ')
                                            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                                            .join(' '),
            admin: response.userData.NIVEL === "GERENTE"
          }
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.storageService.setRememberSession(keepSession)
          this.storageService.setItem('token', JSON.stringify(response.token));
          this.storageService.setItem('userData', JSON.stringify(userData));
          this.toastr.success("Login efetuado com sucesso");
          this.loggedIn$.next(true);
          this.router.navigate(['/visits']);
        }
        return;
      },
        error => {
          this.toastr.error(error.error.message);
        });
  }

  logout(page?: string) {
    this.storageService.removeItem('token');
    this.storageService.removeItem('userData');
    return this.loginService.logout()
      .subscribe(response => {
        this.toastr.warning('Logout efetuado com sucesso!!', 'Logout');
        this.loggedIn$.next(null);
        if (page !== undefined) {
          this.router.navigate([page]);
        }
        return;
      },
        error => {
          this.toastr.error(error.error.message);
        });

  }

}
