import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/shared/services/login.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  public resourceForm: FormGroup;
  public loggedIn =  false;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private authService: AuthService,
    protected formBuilder: FormBuilder
    ) {
      this.authService.loggedIn$.subscribe((loggedIn) => {
        this.loggedIn = loggedIn;
        if (this.loggedIn){
          this.router.navigate(['visits'])
        }
      });
    }

    ngOnInit() {
      this.generateForm();
    }

    login() {
      // this.authService.login(this.resourceForm.value.email, this.resourceForm.value.password);
    }

    logout() {
      this.authService.logout();
      this.router.navigate(['/']);
    }

    generateForm() {
      this.resourceForm = this.formBuilder.group({
        email: [null, [Validators.required, Validators.minLength(3)]],
        password: [null, Validators.required]
      });
    }

}
