import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public menuShow$ = new BehaviorSubject(false);
  get menuShow(): boolean {
    return this.menuShow$.getValue();
  }
  set menuShow(value: boolean) {
    this.menuShow$.next(value);
  }

  public router: Router;
  public toastr: ToastrService;

  constructor(
    private injector: Injector
  ) {
    this.router = this.injector.get(Router);
    this.toastr = this.injector.get(ToastrService);
  }

}
