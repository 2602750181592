import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'dashboard', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginModule'},
  { path: 'products', canActivate: [AuthGuard], loadChildren: './pages/products/products.module#ProductsModule'},
  { path: 'lines', canActivate: [AuthGuard], loadChildren: './pages/lines/lines.module#LinesModule'},
  { path: 'visits', canActivate: [AuthGuard], loadChildren: './pages/visits/visits.module#VisitsModule'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
