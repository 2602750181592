import { Component, OnInit, NgModule } from '@angular/core';
import $ from 'jquery';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@Component({
  selector: 'app-mega',
  templateUrl: './mega.component.html',
  styleUrls: ['./mega.component.scss']
})


@NgModule({
  imports: [
    BrowserAnimationsModule
  ]
})
export class MegaComponent implements OnInit {

  public open = false;
  private spreads = [];

  public resourceForm: FormGroup;

  constructor(

    protected formBuilder: FormBuilder
    ) {
    }

  ngOnInit() {
    this.generateForm();
  }

  generateForm() {
    this.resourceForm = this.formBuilder.group({
      limitPerPage: ['all']
    });
  }

  toggle() {
    this.open = !this.open;
  }

}
