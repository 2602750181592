import { Component, OnInit } from '@angular/core';
import $ from 'jquery';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  private balances = [];

  public collapse = true;

  constructor(
    ) {
    }

  ngOnInit() {
    this.close();
  }


  close() {
      $('.itemMenu').click(() => {
        $('#menuToggle').trigger('click');
      });
  }

}
