import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  @Output() public change = new EventEmitter();

  public login = {
    name: '',
    email: '',
    access_level: 1,
    active: true,
    isLogin: false

  };

  constructor() { }

  logIn() {
    this.login.name = 'Matheus';
    this.login.email = 'matheus@done.com.br';
    this.login.isLogin = true;
    this.change.emit(this.login);
}

  logOut() {
      this.login.name = '';
      this.login.email = '';
      this.login.isLogin = false;
      this.change.emit(this.login);
  }
}
