import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ImageCropperModule } from 'ngx-image-cropper';


import { NavbarComponent } from './components/navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { MegaComponent } from './components/mega/mega.component';
import { MenuComponent } from './components/menu/menu.component';
import { LoginComponent } from './components/login/login.component';
import { FooterComponent } from './components/footer/footer.component';
import { LanguageComponent } from './components/language/language.component';
import { NotificationComponent } from './components/notification/notification.component';
import { MessageComponent } from './components/message/message.component';
import { NavbarRightComponent } from './components/navbar-right/navbar-right.component';
import { MaterialModule } from '../shared/custom/material/material.module';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';

import { ChartsModule } from 'ng2-charts';
import { TitlePipe } from '../shared/pipe-module';

import { DeviceDetectorModule } from 'ngx-device-detector';


@NgModule({
  declarations: [
    NavbarComponent,
    MegaComponent,
    MenuComponent,
    NavbarRightComponent,
    LoginComponent,
    LanguageComponent,
    NotificationComponent,
    MessageComponent,
    FooterComponent,
    ToolbarComponent,
    SidenavComponent,

  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,

    // Material and External Modules Group
    MaterialModule,
    ToastrModule.forRoot(),
    ChartsModule,
    ImageCropperModule,

    DeviceDetectorModule.forRoot(),

  ],
  exports: [

    // shared modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,

    // shared components
    NavbarComponent,
    MegaComponent,
    MenuComponent,
    NavbarRightComponent,
    LoginComponent,
    LanguageComponent,
    NotificationComponent,
    MessageComponent,
    FooterComponent,
    ToolbarComponent,
    SidenavComponent,

    // Material and External Modules Group
    MaterialModule,
    ToastrModule,
    ChartsModule,
    ImageCropperModule,

    DeviceDetectorModule

  ],
  providers: [
    TitlePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule { }
