import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Pipe({ name: 'title' })
export class TitlePipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) { }
  transform(field: string): SafeHtml {
    const titles = JSON.parse(window.localStorage.getItem('titles'));
    return titles[field] || field;
  }
}




@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [ 
    TitlePipe
  ],
  exports: [
    TitlePipe
  ]
})
export class ApplicationPipesModule {}