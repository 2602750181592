import { Component, Output, Injector } from '@angular/core';

import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MenuService } from './shared/services/menu.service';
import { Router } from '@angular/router';
declare var reloadEvents: any;
  // tslint:disable variable-name


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'PDV proanalise';
  hideMenu = false;

  private matIconRegistry: MatIconRegistry;
  private domSanitizer: DomSanitizer;
  private authService: AuthService;
  private menuService: MenuService;
  private router: Router;

  constructor(private injector: Injector) {
    this.matIconRegistry = this.injector.get(MatIconRegistry);
    this.domSanitizer = this.injector.get(DomSanitizer);
    this.authService = this.injector.get(AuthService);
    this.menuService = this.injector.get(MenuService);
    this.router = this.injector.get(Router);
    this.matIconRegistry.addSvgIcon(
      'campanhas',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/svg/menu/svg-megaphone.svg'
      )
    );

    this.authService.loggedIn$.subscribe(loggedin => {
      this.hideMenu = loggedin;
      reloadEvents();
    });
    // this.menuService.menuShow$.subscribe(showMenu => {
    //   this.hideMenu = true;
    // });
  }
}
