import { Component, OnInit, Injector } from '@angular/core';
import $ from 'jquery';
import { LoginService } from 'src/app/shared/services/login.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public open = false;
  private login: any;
  private show =  false;
  public collapse = true;
  public resourceForm: FormGroup;
  protected formBuilder: FormBuilder;

  constructor(
    protected injector: Injector,
    private loginService: LoginService
  ) {
    this.formBuilder = injector.get(FormBuilder);

    this.loginService.change.subscribe((login) => {
      this.login = login;
      this.show = this.login.isLogin;
      if (!this.show) {
        this.open = false;
      }
    });
  }

  ngOnInit() {

    $('.toggle-open').on('click', () => {
      this.toggle();

    });
    this.buildResourceForm();
  }

  protected buildResourceForm() {
    this.resourceForm = this.formBuilder.group({
      id: [null],
      name: [null, [Validators.required, Validators.minLength(3)]],
    });
  }

  toggle() {
    this.open = !this.open;
  }

}
