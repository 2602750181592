import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import * as jwt_decode from 'jwt-decode';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserData } from 'src/app/shared/models/user-data-model';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  private navbarUntouched = true;
  public isMobile;
  public userData: UserData
  constructor(private authService: AuthService, private deviceService: DeviceDetectorService,) {}


  ngOnInit() {
    this.isMobile = this.deviceService.isMobile()
    this.userData = this.authService.getUserData()
  }

  public toggleMenu(reset){
    if (!this.isMobile){
      return
    }
    if (this.navbarUntouched){
      this.navbarUntouched = false
      //return
    }
    if (reset){
      this.navbarUntouched = true
    }
    const menuElement = document.getElementById('my-navbar-collapse')
    if (menuElement.classList.contains('expanded')){
      menuElement.classList.remove('expanded')
    }
    else{
      menuElement.classList.add('expanded')
    }
  }



  logout() {
    this.authService.logout('/login');
  }

}
