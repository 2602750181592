import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService  {

  public baseUrl = environment.baseUrl;

  constructor(
    private http: HttpClient,
    public router: Router,
    ) {
  }

  login(payload: HttpParams): Observable<any> {
    const url = this.baseUrl + 'auth/auth';
    return this.http.post<any>(url, payload);
  }
  logout(): Observable<any> {
    const url = this.baseUrl + 'auth/logout';
    return this.http.post<any>(url, null);
  }
}
