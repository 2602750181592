
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AuthService } from './shared/services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth-interceptor';
import { MatTableFilterModule } from 'mat-table-filter';
import {
  LyThemeModule,
  LY_THEME
} from '@alyle/ui';
/** Import the component modules */
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { MAT_DATE_LOCALE } from '@angular/material';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatTableFilterModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // LyThemeModule.setTheme('minima-light'),
    // // Add components
    // LyButtonModule,
    // LyToolbarModule,
    // LyResizingCroppingImageModule,
    // LyCarouselModule,

  ],
  exports: [
    CoreModule,
    ReactiveFormsModule,
    FormsModule,
    MatTableFilterModule,

  ],
  providers: [
    AuthService,
    AuthGuard,
    { provide: MAT_DATE_LOCALE, useValue: 'id-ID' },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
    { provide: LY_THEME, useClass: MinimaDark, multi: true } // name: `minima-dark`
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
