import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthService } from './shared/services/auth.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from './shared/services/storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    public authService: AuthService,
    public router: Router,
    public toastr: ToastrService,
    public storageService: StorageService
    ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ',
          token: token
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          Authorization: ''
        }
      });
    }
    return next.handle(request).pipe(catchError((error, caught) => {
      this.handleAuthError(error);
      return of(error);
    }) as any);
  }


  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 511) { // não logado
      this.toastr.error(err.error.message);
      this.storageService.removeItem('token');
      this.authService.logout('login');
      return of(err);
    }
    throw err;
  }
}
