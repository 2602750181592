import { Injectable } from '@angular/core';
import {
  CanActivate, CanActivateChild, CanLoad,
  Route, UrlSegment, ActivatedRouteSnapshot,
  RouterStateSnapshot, UrlTree, Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
declare var reloadEvents: any;
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  loggedInStatus = false;

  constructor(
    private authService: AuthService,
    public router: Router,
    public toastr: ToastrService
    ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // return true;

    this.authService.loggedIn$.subscribe(
      (status) => {
        this.loggedInStatus = status;
        reloadEvents();
      });
    if (!this.loggedInStatus) {
      this.toastr.error('Você precisa efetuar o login para acessar este conteúdo!');
      this.router.navigate(['/login']);
    }
    return this.loggedInStatus;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
