import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public rememberSession: boolean;

  constructor() {
    this.rememberSession = localStorage.getItem('rememberSession') == "yes"
    if (this.rememberSession){
      sessionStorage.removeItem('token')
    }
    else {
      localStorage.removeItem('token')
    }
  }

  public setRememberSession(remember: boolean){
    const rememberString: "yes" | "no" = remember ? 'yes' : 'no'
    localStorage.setItem('rememberSession', rememberString)
  }

  public getItem(key: string) {
    if (this.rememberSession) {
      return localStorage.getItem(key)
    }
    else {
      return sessionStorage.getItem(key)
    }
  }

  public setItem(key: string, item: any) {
    if (this.rememberSession) {
      localStorage.setItem(key, item)
    }
    else {
      sessionStorage.setItem(key, item)
    }
  }

  public removeItem(key: string) {
    localStorage.removeItem(key)
    sessionStorage.removeItem(key)
  }

}
