import { Injectable } from '@angular/core';
import { SidenavService } from '../../sidenav/services/sidenav.service';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {

  constructor(
    private sidenav: SidenavService
  ) { }
}
